import { computed, defineComponent, onBeforeUnmount, onMounted, ref, unref, watch, } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { Toast } from "vant";
import { MobileRegexp, loginValidate } from "@/hooks/component/loginValidate";
import { sendLoginVerifyCode, sendRegisterVerifyCode, sendLoginVerifyCodeOld, validateRegisterVerifyCode, } from "@/apis";
import { globalStorage } from "@/utils/storage/base";
export default defineComponent({
    name: "AuthIdentifyCode",
    components: {},
    setup() {
        const { currentRoute, push } = useRouter();
        const store = useStore();
        const isShow = ref(true);
        // 输入框值
        const code = ref("");
        // 输入框长度
        const codeLength = ref(6);
        // 剩余时间
        const durTime = ref(60);
        // 初始化时间
        durTime.value = Number(globalStorage.getFormLocal("auto_login_timer")) ?? 0;
        const isSend = computed(() => durTime.value <= 0);
        const mobile = computed(() => unref(currentRoute).query.mobile ?? "");
        const from = computed(() => unref(currentRoute).query.from ?? "");
        // 微信code码
        const wechatcode = globalStorage.getFormLocal("login_wechat_code") ?? '';
        const showKeyboard = ref(true);
        const { handleLoginCorps, initAwsc } = loginValidate();
        let timer = null;
        // 初始化定时器
        function initInterval() {
            durTime.value = Number(globalStorage.getFormLocal("auto_login_timer")) ?? 0;
            timer = setInterval(() => {
                if (durTime.value <= 0) {
                    clearInterval(timer);
                    timer = null;
                }
                else {
                    durTime.value--;
                    globalStorage.setToLocal("auto_login_timer", `${durTime.value}`);
                }
            }, 1000);
        }
        // 发送登录验证码
        function sendLoginVerify(params) {
            sendLoginVerifyCode({
                mobile: mobile.value,
                ...params,
            }).then((res) => {
                isShow.value = true;
                if (res.errcode === 0) {
                    Toast("验证码发送成功\n请注意手机短信");
                    // 设置持续存储timer
                    globalStorage.setToLocal("auto_login_timer", "60");
                    setTimeout(() => {
                        initInterval();
                    }, 500);
                }
                else if (res.errcode === 202400) {
                    isShow.value = false;
                    window.nvc.getNC({ renderTo: "registerNC" });
                }
                else {
                    Toast(res.errmsg);
                    handleInitAwsc();
                }
            });
        }
        // 发送登录验证码-获取人机验证码
        function sendLoginCaptcha() {
            if (!MobileRegexp.test(mobile.value))
                return;
            window.nvc.getNVCValAsync((nvcVal) => {
                sendLoginVerify({ captcha: nvcVal });
            });
        }
        // 验证登录验证码
        async function identifyLogin(val) {
            // 验证验证码是否正确
            store
                .dispatch("loginWithCodeAction", {
                mobile: mobile.value,
                verifycode: code.value,
                wechatcode
            })
                .then((res) => {
                handleLoginCorps(res);
            })
                .catch((errmsg) => {
                Toast(errmsg);
            });
        }
        // 发送注册验证码
        function sendRegisterVerify(params) {
            sendRegisterVerifyCode({
                mobile: mobile.value,
                ...params,
            }).then((res) => {
                isShow.value = true;
                if (res.errcode === 0) {
                    Toast("验证码发送成功\n请注意手机短信");
                    // 设置持续存储timer
                    globalStorage.setToLocal("auto_login_timer", "60");
                    setTimeout(() => {
                        initInterval();
                    }, 500);
                }
                else if (res.errcode === 402400) {
                    isShow.value = false;
                    window.nvc.getNC({ renderTo: "registerNC" });
                }
                else {
                    Toast(res.errmsg);
                    handleInitAwsc();
                }
            });
        }
        // 发送注册验证码-获取人机验证码
        async function sendRegisterCaptcha() {
            if (!MobileRegexp.test(mobile.value))
                return;
            window.nvc.getNVCValAsync((nvcVal) => {
                sendRegisterVerify({ captcha: nvcVal });
            });
        }
        // 验证注册验证码
        async function identifyRegister(val) {
            // 验证验证码是否正确
            const res = await validateRegisterVerifyCode({
                mobile: mobile.value,
                verifycode: val,
            });
            if (res.errcode === 0) {
                handleRegisterCorps(res);
            }
            else {
                Toast(res.errmsg || "获取短信验证码失败");
                code.value = "";
            }
        }
        // 发送重置密码验证码
        function sendResetVerify(params) {
            sendLoginVerifyCodeOld({
                mobile: mobile.value,
                ...params,
            }).then((res) => {
                isShow.value = true;
                if (res.errcode === 0) {
                    Toast("验证码发送成功\n请注意手机短信");
                    // 设置持续存储timer
                    globalStorage.setToLocal("auto_login_timer", "60");
                    setTimeout(() => {
                        initInterval();
                    }, 500);
                }
                else if (res.errcode === 402400) {
                    isShow.value = false;
                    window.nvc.getNC({ renderTo: "registerNC" });
                }
                else {
                    Toast(res.errmsg);
                    handleInitAwsc();
                }
            });
        }
        // 发送重置密码验证码-获取人机验证码
        async function sendResetCaptcha() {
            if (!MobileRegexp.test(mobile.value))
                return;
            window.nvc.getNVCValAsync((nvcVal) => {
                sendResetVerify({ captcha: nvcVal });
            });
        }
        // 处理注册多企业逻辑
        function handleRegisterCorps(res) {
            // 有多个企业让用户跳转创建企业 如果没有用户则跳转设置密码
            let name = "AuthPasswordSet";
            if (res.haveuser) {
                const corpnames = res.corpnames ?? [];
                const corps = corpnames.map((item) => ({
                    corpname: item.Value,
                    corpcode: item.Key,
                }));
                // 避免企业过多数据丢失、防止刷新页面数据丢失
                globalStorage.setToLocal("select_token", res.token);
                globalStorage.setToLocal("select_corps", JSON.stringify(corps));
                name = "AuthCorpCreate";
            }
            else {
                globalStorage.setToLocal("select_token", "");
                globalStorage.setToLocal("select_corps", "");
            }
            push({
                name,
                query: {
                    from: from.value,
                    mobile: mobile.value,
                    wechatcode
                },
                params: {
                    token: res.token,
                    haveuser: res.haveuser,
                },
            });
        }
        // 验证重置验证码(目前在最后一步验证)
        function identifyReset(val) {
            push({
                name: "AuthPasswordSet",
                query: {
                    from: from.value,
                    mobile: mobile.value,
                    verifycode: val,
                    wechatcode
                }
            });
        }
        // 发送验证码
        function sendCaptcha() {
            if (from.value === "login") {
                sendLoginCaptcha();
            }
            else if (from.value === "register") {
                sendRegisterCaptcha();
            }
            else if (from.value === "reset") {
                sendResetCaptcha();
            }
        }
        // 销毁时清空定时器
        onBeforeUnmount(() => {
            clearInterval(timer);
            timer = null;
        });
        onMounted(() => {
            if (mobile.value === "") {
                Toast("链接异常，请返回上一步");
                return;
            }
            if (durTime.value > 0) {
                initInterval();
            }
        });
        // 初始化
        function handleInitAwsc() {
            if (from.value === "login") {
                initAwsc('login', sendLoginVerify);
            }
            else if (from.value === "register") {
                initAwsc('register', sendRegisterVerify);
            }
            else if (from.value === "reset") {
                initAwsc('login', sendResetVerify);
            }
        }
        // 惰性
        watch(code, (newVal, oldVal) => {
            if (newVal.length === codeLength.value) {
                showKeyboard.value = false;
                if (from.value === "login") {
                    identifyLogin(newVal);
                }
                else if (from.value === "register") {
                    identifyRegister(newVal);
                }
                else if (from.value === "reset") {
                    identifyReset(newVal);
                }
            }
        });
        handleInitAwsc();
        return {
            isShow,
            code,
            codeLength,
            durTime,
            isSend,
            mobile,
            showKeyboard,
            sendCaptcha,
        };
    },
});
